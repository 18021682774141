.navbar-brand-img {
	border: 4px solid #d7a74f;
	position: absolute;
	border-radius: 50%;
	height: auto;
	width: 6rem;
}

.navbar-brand {
	font-family: Rationale;
  color: #000;
	width: fit-content;
}

.navbar-rounded {
	border-bottom-left-radius: 3rem;
	border-bottom-right-radius: 3rem;
}

.active {
	text-underline-offset: 3px;
	text-decoration: underline !important;
	text-decoration-color: #79201b !important;
}

@media screen and (max-width: 1000px) {
  .navbar-nav {
  	width: 100%;
  	padding: 8rem 0 !important;
  	text-align: center;
 		/* background: linear-gradient(180deg, #063A50 0%, #155E6C 75%) */
 	}
 	.bg-secondary {
 		margin-top: 1rem;
 	}
 	.nav-item {
 		margin: 1vw
 	}
	.navbar-brand-img {
		position: sticky;
		width: 4rem;
	}
	.navbar-brand {
		width: 90%;
		text-align: center;
	}
}

.navbar-toggler {
  border: 0;
  border-radius: 0;
	box-shadow: none !important;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	background-image: url("~assets/images/icon_close.png");
	opacity: 0.6
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0
}

.navbar-dark .navbar-toggler {
  border-color: rgba(0,0,0,0)
}

