@font-face {
  font-family: Rationale;
  src: url(assets/fonts/Rationale-Regular.ttf);
}

@font-face {
  font-family: Righteous;
  src: url(assets/fonts/Righteous-Regular.ttf);
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/Roboto-Regular.ttf);
}

body {
  transition: 0.5s;
}

.bg-color,
.bg-color-primary {
  background: #fff !important;
  color: #000 !important;
}

.bg-color-maroon {
  background-color: #79201b !important;
  color: #fff;
}

.bg-color-gold {
  background-color: #d7a74f !important;
}

.text-color-gold {
  color: #d7a74f !important;
}

.text-color-maroon {
  color: #79201b !important;
}

.font-rationale {
  font-family: Rationale;
}

.font-righteous {
  font-family: Righteous;
}

.font-roboto {
  font-family: Roboto;
}

.bg-img {
  width: 100%;
  margin: 0;
  padding: 0;
}

.heading {
  /* letter-spacing: 6px; */
  color: #ffffff;
}

.zindex-tooltip {
  z-index: 1000;
}

.mw-50 {
  min-width: 50%;
}

@media (max-width: 700px) {
  .card {
    min-width: 75vw !important;
  }
}

.w100 {
  width: 100% !important;
}

@media (min-width: 700px) {
  .w100 {
    width: 50% !important;
  }
}

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}

.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #7c251d;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}

.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}

.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #7c2d5f;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

input[type="radio"]:checked+label {
  color: #fff;
  border: 5px solid #d7a74f;
}

input[type="radio"]+label:hover {
  color: #fff;
}

input[type="radio"]:checked+label>div {
  padding-top: 200px !important;
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.8)) !important;
  color: #000;
}

input[type="radio"]+label {
  color: rgba(255, 255, 255, 0.5);
}

.header-img {
  width: 70%;
}

.w-80 {
  width: 100%;

  /* // only for bigger windows */
  @media (min-width: 700px) {
    width: 80%;
  }
}

.vote-nav-item .nav-link {
  color: white !important;
  font-weight: bold;
  border-bottom-color: #79201b !important;
}

.vote-nav-item .disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  font-weight: normal;
}

.vote-nav-item .active {
  background-color: #79201b !important;
  color: #fff !important;
  border-color: #79201b !important;
  margin-bottom: -2px !important;
}
