body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ol {
  counter-reset: item;
}

ol li {
  display: block;
}

ol li:before {
  content: counter(item) ". ";
  counter-increment: item;
  font-weight: bold;
}

.sign-out-btn {
  cursor: pointer;
  text-decoration: underline !important;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.table-responsive {
  display: flex;
  justify-content: space-between;

  /* media query for phone */
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
